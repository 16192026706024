import React from "react";
import "../Aboutus/Aboutus.css";
import Text from "../Text/Text";
import Plan from "../Plan/Plan";
import Details from "../Details/Details";
import { FaHandPointRight } from "react-icons/fa";
const Aboutus = () => {
  const leftPoints = [
    "World-class development by RG Group in Sector 16B Noida Extension.",
    "Premium 3 BHK apartments for sale in Greater Noida West.",
    "Starting price in affordable prices for luxury apartments.",
    "Strategically located with excellent connectivity, just 43.2 KM from Indira Gandhi International Airport.",
    "The Project RERA No:Tower K & L - UPRERAPRJ448790 available at :http://up-rera.in",
    "Spread over 18.4 acres with 29 floors in each of the 13 towers, offering a total of 2500 units.",
  ];

  const rightPoints = [
    "Amenities include solar energy utilization, swimming pool, landscaped garden, and 100% power backup.",
    "Eco-friendly landscaped podium garden and 4 spacious lifts in each tower.",
    "Near essential services: Arogaya Hospital (3.7 KM), Ryan International School (2 KM).",
    "PKS Town Central is only 650 meters away.",
    "Just a 5-minute drive from FNG Corridor and closer to main areas.",
    "7-minute drive from Sec 120 Noida and 8 minutes away from Sai Mandir.",
  ];
  return (
    <>
      <div className="poster-section2">
        <img src="g1.jpg" alt="Poster" className="poster-image2" />
      </div>
      <div className="about-section">
        <h2 className="section-heading2">RG Luxury Homes Noida Extension</h2>
        <div className="about-content">
          <div className="left-ab">
            <ul className="about-list">
              {leftPoints.map((point, index) => (
                <li key={index} className="about-list-item">
                  <span className="about-icon">
                    <FaHandPointRight className="iconar" />
                  </span>
                  {point}
                </li>
              ))}
            </ul>
          </div>
          <div className="right-ab">
            <ul className="about-list">
              {rightPoints.map((point, index) => (
                <li key={index} className="about-list-item">
                  <span className="about-icon">
                    <FaHandPointRight className="iconar" />
                  </span>
                  {point}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <Details />
      <Plan />
    </>
  );
};

export default Aboutus;
