import React, { useState, useEffect } from "react";
import "../Slider/Slider.css"; 
import { GrLinkPrevious,GrLinkNext } from "react-icons/gr";
const Slider = () => {
  const [current, setCurrent] = useState(0);
  const slides = [
    {
      src: "g8.jpg",
      caption: "Green Environment",
    },
    {
      src: "g11.jpg",
      caption: "Beautifull Ambiance",
    },
    {
      src: "fs1.jpg",
      caption: "Luxury Entrance",
    },
  
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrent(prev => (prev + 1) % slides.length);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [slides.length]);

  const handleNext = () => {
    setCurrent(prev => (prev + 1) % slides.length);
  };

  const handlePrev = () => {
    setCurrent(prev => (prev - 1 + slides.length) % slides.length);
  };

  return (
    <div className="slider">
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`slide ${index === current ? "show" : ""}`}
          style={{ display: index === current ? "block" : "none" }}
        >
          <img src={slide.src} alt={`Slide ${index + 1}`} />
          <div className="caption">{slide.caption}</div>
        </div>
      ))}
      <a className="prev" onClick={handlePrev}>
      <GrLinkPrevious />
      </a>
      <a className="next" onClick={handleNext}>
      <GrLinkNext />
      </a>
    </div>
  );
};

export default Slider;
