import React from "react";
import "../Footer/Footer2.css";

const Footer2 = () => {
  return (
    <footer className="footer2">
      <p className="disclaimer-text">
        © 2024 RG Luxury Homes website is operated by an authorized channel
        partner of RG Luxury Homes - Shishir, UP RERA Registration No.
        UPRERAAGT24002. The information provided on this website is intended for
        informational purposes only. All images are for illustrative purposes.
        Prices are subject to change without prior notice, and the availability
        of properties may vary. By providing your contact information, you may
        receive updates via the registered mobile number and email.
      </p>
      <div className="footer-links">
        <a href="/privacy" className="footer-link">
          Disclaimer & Privacy Policy
        </a>
      </div>
    </footer>
  );
};

export default Footer2;
