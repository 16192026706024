import React from "react";
import "../Text/Text.css";
const Text = () => {
  return (
    <div className="luxury-homes-section">
      <div className="container2">
        <h2 className="section-heading">RG Luxury Homes Noida Extension</h2>
        <p className="section-text">
          Established at Sector 16B Noida Extension, RG Luxury Homes is a
          world-class development by RG Group. It offers 3 BHK apartments for
          interested buyers and investors.
        </p>
        <p className="section-text">
          You will be happy to know that these world-class homes are developed
          by RG Builders, offering Flats for Sale in RG Luxury Homes Phase 2
          Greater Noida West. This development offers premium 3 BHK apartments
          only.
        </p>
        <p className="section-text">
          RG Luxury Homes Noida Extension offers many amenities for a
          comfortable life, including provisions for solar energy utilization, a
          swimming pool, an eco-friendly landscaped podium garden,
          round-the-clock security, and 100% power backup in common areas.
        </p>
        <p className="section-text">
          RG Luxury Homes Phase 2 Price List offers Premium Apartments starting
          in affordable prices. The development offers luxury homes for
          everyone. Your loved ones will fall in love with this space—a space
          closer to lush greenery and well-connected to wide roads.
        </p>
        <p className="section-text">
          RG Luxury Homes Phase 2 Noida Extension is a grand development with an
          eco-friendly landscaped podium garden and provisions for solar energy
          utilization.
        </p>
        <p className="section-text">
          For RG Luxury Homes Price List, you can call us! This development
          offers 100% power backup in common areas and has a fresh water supply.
          It is just a 5-minute drive from FNG Corridor and is closer to main
          areas.
        </p>
        <p className="section-text">
          This development offers premium apartments, with cupboards in each
          bedroom. It has 4 spacious lifts for each tower, and you will also
          find a 24X7 security system with CCTV provision at RG Luxury Homes
          Greater Noida West.
        </p>
        <p className="section-text">
          Premium 3 BHK apartments with a built-up area of 1175 Sq. Feet as per
          RG Luxury Homes Floor Plan. It is an under-construction project
          strategically located in Sector 16 B, Greater Noida, as per recent
          construction status. Since April 2024, this development is a
          world-class project as per RG Luxury Homes Construction Update, and it
          is expected to be completed soon.
        </p>
        <p className="section-text">
          The RG Luxury Homes Site Plan shows that this property is spread over
          18.4 acres. It has 29 floors in each of the 13 towers, offering a
          total of 2500 units. As per RG Luxury Homes Location Map, this
          development is in a strategic location. PKS Town Central is only 650
          meters away, making RG Luxury Homes special.
        </p>
        <p className="section-text">
          RG Luxury Homes Possession Date is March 2026, and the Project RERA
          No:Tower K & L - UPRERAPRJ448790 available at :http://up-rera.in . It offers luxury homes for anyone looking for a
          peaceful lifestyle. This under-construction project is set to begin
          possession in March 2026.
        </p>
        <p className="section-text">
          RG Luxury Homes Sector 16B Greater Noida West is only 43.2 KM away
          from Indira Gandhi International Airport. Arogaya Hospital is 3.7 KM
          away, and Santosh Deemed to be University is just 12.9 KM away. Ryan
          International School, Noida Extension, is just 2 KM away, and NH 34,
          Chhapraula, is only 5 KM away.
        </p>
        <p className="section-text">
          As per the RG Luxury Homes PDF Brochure, the connectivity is
          excellent. It is just a 7-minute drive from Sec 120 Noida and 8
          minutes away from Sai Mandir. Say yes to RG Luxury Homes now, and
          check out RG Mirage, a new project in Noida Sector 120.
        </p>
        <p className="section-text">
          RG Luxury Homes Possession Date: The possession of RG Luxury Homes is
          planned within 36 months from the project launch date.
        </p>
      </div>
    </div>
  );
};

export default Text;
