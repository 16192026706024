import React from 'react';
import '../Pricelist/Pricelist.css';
import Price from '../Price/Price';

const Pricelist = () => {
  return (
    <>
      <div className="poster-section">
        <img src="poster.jpg" alt="Poster" className="poster-image" />
      </div>
     
      <Price />
    </>
  );
};

export default Pricelist;
