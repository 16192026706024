import React from 'react';
import { FaPhone } from 'react-icons/fa';
import '../Location/Location.css';

const Location = () => {
  return (
    <>
    <div className='full-l'>
         <h1 className='heading-l'>Luxury Home Location Map</h1> 
   
    <div className="location-section">
  
      <div className="L-l">
        <img src="location.jpg" alt="Location" />
      </div>
      <div className="L-r">
        <h2>RG Luxury Homes</h2>
        <h5>Sec-16B, Greater Noida West<br />
            Uttar Pradesh-201306</h5>
        <a href="tel:+917303734500" className="call-now">
          <FaPhone className="call-icon" />
          <span>Call Now</span>
        </a>
      </div>
    </div>
    </div>
    </>
  );
};

export default Location;
